import React from "react";
import { graphql } from "gatsby";
import { GutterWrapper, PageSectionImageText, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";

const SectionWrapper = styled.div`
    margin-bottom: 100px;
`;

const Page = ({ data }) => {
    const { site, section_1_img_1 } = data;
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "home"
    });

    config.page.content.sections[0].panels[0].image.src = getImage(section_1_img_1);

    return (
        <PageTemplateWrapper config={config}>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[0]} />
                </SectionWrapper>
            </GutterWrapper>
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    {
        site {
            ...SiteFragment
        }
        section_1_img_1: file(relativePath: { eq: "images/three-women-phone.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1034, height: 586, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
    }
`;
